
.floaty-middle{
    background-color: rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 30vw;
    min-height: 50vh;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.1);
    height: fit-content;
    padding: 25px;
    text-align: left;
}
