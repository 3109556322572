.logo{
  font-size: 2rem !important;
  font-family: "Source Sans Pro";
  font-weight: 550 ;
  text-align: left;
  color: white !important;
}

@media only screen and (max-width: 900px) {
  .logo{
      color:white;
      font-size: 25px;
  }
}