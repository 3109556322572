.main-content{
    width:100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    height:  calc(100vh);
    overflow-x: hidden;
}

@media only screen and (max-width: 900px) {
    .main-content{
        padding: 0;
        gap:0px;}
        
  }