.manually-styled-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .manually-styled-table  td, .manually-styled-table  th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .manually-styled-table  tr:nth-child(odd){background-color: #f2f2f2;}
  .manually-styled-table  tr:nth-child(even){background-color: #ebe9e9;}
  
  /* .manually-styled-table  tr:hover {background-color: #ddd;} */
  
  .manually-styled-table  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #1c2e44;
    color: white;
  }