#main-menu-div{
    background-color: #1c2e44;
    color: white;
    position: static;
    height:100vh;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}
.menu-category{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex-grow: 1;
    text-decoration: none;
}
.menu-category *{
    text-decoration: inherit;
    font-size: 1rem;
    font-weight:700;
}
.formatted-link{
    text-wrap: nowrap;
    color: white;
    border-radius: 25px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
        
}
.formatted-link:hover{
background-color: rgba(255, 255, 255, 0.4);
}

.formatted-link.active{
background-color: rgba(255, 255, 255, 0.4);
}

@media only screen and (max-width: 900px) {
    #main-menu-div *{
        font-weight: 700;
        font-size: 1rem;
    }
    .formatted-link{
        text-wrap: wrap;
    }
}