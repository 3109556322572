.summary-cards-three{
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap:10px;
    justify-content:space-between;
    align-items: center;
    align-content: stretch;
    margin-bottom: 10px;
  }

  .summary-card-shadowed{
    color:#236382;
    height: 100%;
    justify-content: space-between;
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
  }