.mid-title{
    color: #1c2e44;
    font-size: 2rem;
    text-align: center;
    width:100%;
    padding: 15px 0px;
}
.content-in-white{
    /* background-color: #ffffff; */
    /* border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
    padding:20px; */

}