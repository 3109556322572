.not-found-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.huge-text-404{
    color: #236382;
    font-size: 20vw;
    font-weight: 600;
    text-align: center;
}
.big-text-404{
    color: #236382;
    font-size: 3vw;
    text-align: center;

}